/** @jsxImportSource @emotion/react */
import { Button } from 'components'
import { useMemo } from 'react'
import { Label } from 'reactstrap'
import { EDITOR_ROLE_PRIORITY } from 'utils/constants'
import { useBaselineFormController } from './BaselineForm.controller'
import {
  StyledForm,
  StyledInput,
  StyledSelect,
  StyledButtonWrapper,
  StyledDeleteButton,
  styles,
} from './BaselineForm.styles'

export const BaselineForm = () => {
  const {
    form,
    selected,
    mode,
    userListData,
    statusListData,
    isDefaultMode,
    isEditMode,
    isLoading,
    isReadOnly,
    isPrimaryButtonDisabled,
    handleChange,
    handleSelectChange,
    handlePrimaryButtonClick,
    handleDelete,
    handleCancel,
  } = useBaselineFormController()

  const buttonText = useMemo(() => {
    if (isDefaultMode) return 'Add'
    if (mode === 'edit') return 'Save'
    return 'Create'
  }, [mode, isDefaultMode])

  return (
    <>
      <StyledForm css={styles.form}>
        <Label>Baseline version</Label>
        <StyledInput
          css={styles.input}
          name="baselineVersion"
          placeholder="Baseline version"
          value={form.baselineVersion}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode || isLoading}
        />
        <Label>Software version</Label>
        <StyledInput
          css={styles.input}
          name="softwareVersion"
          placeholder="Software version"
          value={form.softwareVersion}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode || isLoading}
        />
        <Label>Technology</Label>
        <StyledInput
          css={styles.input}
          name="technology"
          placeholder="Technology"
          value={form.technology}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode || isLoading}
        />
        <Label>Manufacturer</Label>
        <StyledInput
          css={styles.input}
          name="manufacturer"
          placeholder="Manufacturer"
          value={form.manufacturer}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode || isLoading}
        />
        <Label>Hardware Type</Label>
        <StyledInput
          css={styles.input}
          name="hardwareType"
          placeholder="Hardware Type"
          value={form.hardwareType}
          onChange={handleChange}
          disabled={isReadOnly || isDefaultMode || isLoading}
        />
        {(isDefaultMode || isEditMode) && (
          <>
            <Label>Owner</Label>
            <StyledSelect
              css={styles.select}
              className="react-select primary"
              classNamePrefix="react-select"
              name="owner"
              menuPlacement="top"
              maxMenuHeight="13rem"
              options={userListData}
              value={form.owner}
              onChange={handleSelectChange}
              isDisabled={isReadOnly || isDefaultMode || isLoading}
            />
          </>
        )}
        {(isDefaultMode || isEditMode) && (
          <>
            <Label>Status</Label>
            <StyledSelect
              css={styles.select}
              className="react-select primary"
              classNamePrefix="react-select"
              name="status"
              menuPlacement="top"
              maxMenuHeight="13rem"
              options={statusListData}
              value={form.status}
              onChange={handleSelectChange}
              isDisabled={isReadOnly || isDefaultMode || isLoading}
            />
          </>
        )}
      </StyledForm>

      <StyledButtonWrapper css={styles.buttonWrapper}>
        {isEditMode && (
          <StyledDeleteButton
            outline
            color="danger"
            css={styles.delete}
            priority={EDITOR_ROLE_PRIORITY}
            handleClick={handleDelete}
            isDisabled={isReadOnly || !selected || isLoading}
          >
            Delete
          </StyledDeleteButton>
        )}
        {!isDefaultMode && (
          <Button outline isCancelButton handleClick={handleCancel} isDisabled={isLoading}>
            Cancel
          </Button>
        )}
        <Button
          handleClick={handlePrimaryButtonClick}
          priority={EDITOR_ROLE_PRIORITY}
          isDisabled={isReadOnly || isPrimaryButtonDisabled}
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
